import {httpPost} from "@/utils/fetch";


//oss凭证
export const getUpdateSignature = params => {
  return httpPost(process.env.VUE_APP_BASE_OSS_API,params)
  // return httpPost(`http://183.230.181.120:9090/kl-oss-biz/credential/getUpdateSignature`,params)
}

//通过ocr验证活体
export const livePersonAuditByOcr = params => {
  return httpPost(`/check/livePersonAuditByOcr`,params)
}
