<template>
  <div class="opinion">
    <div class="list">
        <van-cell
            style="height: 48px; display: flex; align-items: center;padding-left:0;padding-right:0"
            title="选择反馈类型"
            :value="opinionName"
            is-link
			@click="handlePickShow"
        />
    </div>
    <div class="list">
	  	<van-cell
            style="height: 48px; display: flex; align-items: center;padding-left:0;padding-right:0"
            title="请输入反馈信息"
        />
      <div class="box">
        <div class="inputBox">
          <textarea placeholder="描述问题的详细情况，有助于我们快速帮您解决~" v-model="description"
              @input="handleTextNum" maxlength="500"
          >
          </textarea>
          <div class="text-num color-999">{{textNum}}/500</div>
        </div>
      </div>
    </div>
    <!-- <i-input :value="contactInfo" title="手机/邮箱/QQ(选填)：" type="text"  placeholder="请输入~" /> -->
    <div class="contactInfo bg-fff">
		<div>
			<span>手机/邮箱/QQ(选填)：</span>
		</div>
		<div class="inp">
			<input type="text" v-model="contactInfo" placeholder="请输入~" />
		</div>
	  
    </div>
    <div class="upload">
		<div class="title">上传图片(最多6张)(选填)</div>
		<div class="img-box flex">
			<div class="item" v-for="(item, index) in imgBox" :key="index" >
				<!-- <van-uploader :after-read="getImage"  accept="image/*" multiple> -->
					<input type="file" accept="image/*"
						ref="uploaderImg" 
						class="uploader-img"
						id="upload"
						@change="getImage(index)"
						multiple 
						v-if="!item.resId"
					>
					<img :src="item.imgUrl" :class="item.resId?'big-img':'default'" 
						@click="previewImg(index)"
					/>
					<img :src="item.img" alt="" />
					<img src="@/assets/yq/img-delete.png" class="img-delete" v-show="item.resId"
						@click.stop="deleteImg(index)" />
				<!-- </van-uploader> -->
			</div>
		</div>
    </div>
    <div class="btn" @click="submit">
      <zlButton text="提交" />
    </div>
    <opinionConfirm :isShow="cModal" @cancel="handleConfirmCancel" />
	<div class="picker">
		<van-popup v-model="pickerShow" position="bottom" round>
			<van-picker
				title="选择反馈类型"
				show-toolbar
				:columns="opinionArray"
				@confirm="onConfirm"
				@cancel="onCancel"
			/>
		</van-popup>
	 </div>
  </div>
</template>

<script>
	import opinionConfirm from "@/components/mine/opinionConfirm";
	import zlButton from "@/components/zlButton.vue";
	import { getUpdateSignature } from "@/api/chengx/";
	import { addFeedback } from "@/api/user";
	import axios from 'axios';
	import { ImagePreview } from 'vant';
	import { getAuthInfo } from "@/utils/auth";
	import { compressImg } from "@/utils/index";

  	export default {
		components: {
		zlButton,
		opinionConfirm
		},
    data() {
      return {
        title: "意见反馈",
        backVisible: true,
        value1: "",
        index: "",
        img_oss: null,
        opinionArray: [
			"展示信息异常",
			"认证失败",
			"合同内容不符",
			"合同延期",
			"专业顾问联系补上",
			"产品功能",
			"其他问题",
        ],
        opinionName: "",
        opinionType: "",
        description: "",
        contactInfo: "",
        images: [
		],
        textNum: 0,
        defaultImg: require("@/assets/yq/upload-photo.png"),
        cModal: false,
		pickerShow: false,
        imgBox: [
			{
				resId: "",
				imgUrl: require("@/assets/yq/upload-photo.png"),
        	}
		],
		imgCount:0,
		imgMaxSize: 1 * 1024 * 1024,//图片最大1M,否则
      };
    },
	computed: {
		imgUrls() {
			let imgs = this.imgBox;
			let images = [];
			for (var i = 0; i < imgs.length; i++) {
				if (imgs[i].resId) {
					images.push(imgs[i].imgUrl);
				}
			}
			return images
		}
	},
    created(){
      	this.reset();
		this.getStorePublicInfo();
		
		let authInfo = JSON.parse(getAuthInfo());
		if( !authInfo.checkPass ){
			this.$toast({
				message: "请实名认证"
			})
			setTimeout(() => {
				this.$router.go(-1)
			}, 2500);
		}
    },
    methods: {
		handlePickShow(){
			this.pickerShow = true;
		},
		onConfirm(value, index) {
            this.pickerShow = false;
            this.opinionType = index;
			this.opinionName = value;
            // this.modifyUserInfo();
        },
		onCancel() {
            this.pickerShow = false;
        },
		handleConfirmCancel() {
			this.cModal = false;
		},
		handleTextNum() {
			let len = this.description.length;
			this.textNum = len;
		},
		// 预览图片
		previewImg(index) {
			let imgBox = this.imgBox;
			let urls = [];
			imgBox.forEach(val=>{
				if(val.resId){
					urls.push(val.imgUrl);
				}
			})
			ImagePreview({
				images: urls,
				startPosition: index,
			});
		},
      	submit() {
			let data = {
				description: this.description,
				contactInfo: this.contactInfo,
				type: this.opinionType,
				images: this.imgUrls,
			};
			console.log("点击了提交", data);
			if (data.type === "" ) {
				this.$toast({
					message: "请选择反馈类型!"
				})
				return;
			}
			if (data.description === "" ) {
				this.$toast({
					message: "请请填写反馈信息!"
				})
				return;
			}
			addFeedback(data).then(res => {
				let { status,statusMessage,statusCode } = res;
				if (status && statusCode === 0) {
					this.reset();
					this.cModal = true;
					setTimeout(() => {
						this.cModal = false;
						this.$router.go(-1);
					}, 2000);

				}
			}).catch(res => {
				console.log('新增反馈', res);
			})

      	},
		reset() {
			this.imgBox = [
				{
					resId: "",
					imgUrl: require("@/assets/yq/upload-photo.png"),
				}
			];
			this.opinionName = "";
			this.description = "";
			this.contactInfo = "";
			this.images = [];
			this.opinionType = "";
			this.index = 0;
			this.textNum = 0;
		},
		bindPickerChange: function(e) {
			let idx = parseInt(e.mp.detail.value);
			// console.log('picker发送选择改变，携带值为', e.detail.value)
			this.index = idx;
			this.opinionName = this.opinionArray[idx];
			this.opinionType = idx;
		},
		getStorePublicInfo() {
			this.$store.dispatch("app/setLoading",false);
			getUpdateSignature({
				data: {
					ossGroupId: process.env.VUE_APP_OSS_GROUP_OPINION_ID,
				},
			}).then((res) => {
				this.$store.dispatch("app/setLoading",true);
				if (res.statusCode === "00000" && res.data) {
					this.img_oss = res.data;
				}
			}).catch((err) => {
				this.$store.dispatch("app/setLoading",true);
				console.log(err);
			});
		},
      	deleteImg(idx) {

            let j = 0;
            for (var i = 0; i < this.imgBox.length; i++) {
                if(this.imgBox[i].resId){
                    j++
                }
            }
            console.log(j);
            if(j == 6){
                this.imgBox.push({
                    resId: "",
                    imgUrl: require("@/assets/yq/upload-photo.png"),
                })
                // console.log(this.imgBox);
                this.imgBox.splice(idx, 1);
                // let obj = {
                //     resId: "",
                //     imgUrl: "@/assets/yq/upload-photo.png",
                // };
                // this.$set(this.imgBox, j, obj);
            }else{
                this.imgBox.splice(idx, 1);
            }

      	},
      	addDefaultImg(num){
			for (var i = 0; i < num; i++) {
				this.imgBox.push({
					resId: "",
					imgUrl: this.defaultImg,
				})
			}
			this.imgBox=this.imgBox.slice(0,6);
			console.log('addDefaultImg---',this.imgBox);
      	},
      	countResId(){
			let j = 0;
			for (var i = 0; i < this.imgBox.length; i++) {
				if(this.imgBox[i].resId){
					j++;
				}
			}
			return 6-j;
     	},
		getStartPost(){
			for (var i = 0; i < this.imgBox.length; i++) {
				if(!this.imgBox[i].resId){
					return i;
				}
			}
		},
		getImage(idx){
			let files = this.$refs.uploaderImg[0].files
			if(!files && !files[0]){
				return;
			}
			let count = 1;
			count = files.length;
			
			//获取已上传图片数量
			let hasCount = this.countResId();
			if( count > hasCount || count > 6 ){
				this.$toast({
					message: "上传图片最多6张"
				})
				return;
			}
			// this.addDefaultImg(count);
			let start = this.getStartPost();
			let startB = start;
			this.imgCount = count;

			//添加加载提示
			this.$toast.loading({
				message: '加载中...',
				duration: 0
			});
			//循环上传图片
			for( let i=0;i<count;i++ ){
				var startC = start;
				compressImg(files[i]).then(res=>{
					console.log('压缩后',res);
					this.handleUpload(res,count,startC);	
				})	
				start++;
			}

			//定时请求上传图片是否都成功，成功就关闭加载弹窗
			let cTimer = setInterval(() => {
				let flag = 0;
				for( let j=0;j<this.imgBox.length;j++ ){
					if(this.imgBox[j].resId){
						flag++;
					}
				}
				if(flag == (count + startB)){
					clearInterval(cTimer);
					this.$toast.clear();
				}
			}, 1000);
						
		},
		handleUpload(file,count,idx){
			let data = this.img_oss;
			var formData = new FormData();
			formData.append(
				"key",`${data.dir}${new Date().getTime()}.png`
			);
			formData.append("OSSAccessKeyId", data.accessId);
			formData.append("callback", data.callback);
			formData.append("date", data.date);
			formData.append("policy", data.policy);
			formData.append("Signature", data.signature);
			formData.append("file", file); // 接口需要传的参数
			
			axios({
				url: data.host,
				headers: {
					"Content-Type": "multipart/form-data",
					"Access-Control-Allow-Origin": "*",
				},
				method: "post",
				// responseType: 'json',
				data: formData,
			}).then(res=>{
				
				let {statusCode,data} = res.data;
				if(statusCode === "00000"){
					this.imgBox.forEach(val=>{
						if(!val.resId){
							val.imgUrl = data.permanentUrl;
							val.resId = data.id;
							return;
						}
					});
					// this.imgBox[idx].imgUrl = data.permanentUrl;
					// this.imgBox[idx].resId = data.id;
					if(this.imgBox.length < 6){
						this.imgBox.push({
							resId: "",
							imgUrl: require("@/assets/yq/upload-photo.png"),
						})
						this.imgBox.splice(6);
					}
					
				}
			})
		}
    },
  };
</script>

<style scoped>
	div {
		box-sizing: border-box;
	}
	.opinion{
		padding-top: 50px;
	}

  .opinion .list {
    background-color: #ffffff;
    padding: 0 15px;
    margin-bottom: 10px;

  }

  .opinion .box {
    padding-bottom: 20px;
  }

  .opinion .inputBox {
    height: 192px;
    overflow: hidden;
    position: relative;
  }

  .opinion .contactInfo {
	display: flex;
	box-sizing: border-box;
	width: 100%;
	overflow: hidden;
	color: #323233;
	padding: 15px 19.5px;
	font-size: 14px;
	line-height: 24px;
	background-color: #fff;
	height: 55px;
	align-items: center;
  }
	.opinion .contactInfo div{
		width:150px;
	}
	.opinion .contactInfo .inp{
		width: 180px;	
	}
	.opinion .contactInfo .inp input{
		height: 50px;
	}
	input{
		font-size: 18px;
	}
	input::-wbkit-input-placeholder{
		font-size:16px;
		
	}
  .opinion .contactInfo span{
	 font-size: 14px;
	 font-family: Noto Sans S Chinese;
	 font-weight: 400;
	 color: #222222;
	  
  }
  .opinion .contactInfo input {
    font-size: 14px;
    color: #222222;
    /* margin-left: 5px; */
	height: 100%;
	line-height: 55px;
  }

  .opinion .inputBox textarea {
    width: 336px;
    height: 192px;
    border: none;
    background: #fbfbfb;
    font-size: 14px;
    color: #222;
    padding: 10px 10px 40px 10px;
    /* padding: 18.5px 15px 40px 15px; */
    box-sizing: border-box;
  }

  .opinion .inputBox .text-num {
    position: absolute;
    bottom: 15px;
    right: 15px;
    font-size: 12px;

  }

  .opinion .upload {
    margin-top: 10px;
    background-color: #ffffff;
    padding: 20px 15px;
  }

  .opinion .upload .title {
    font-size: 14px;
    color: #999999;
  }

  .opinion .upload .img-box {
    flex-wrap: wrap;
    margin-top: 18px;
  }

  .opinion .upload .item {
    margin-right: 10px;
    width: 105px;
    height: 105px;
    line-height: 105px;
    text-align: center;
    border: 1px dashed #d0d0d0;
    margin-bottom: 10px;
    border-radius: 10px;
    position: relative;
  }

  .opinion .upload .item .default {
    width: 32.5px;
    height: 28.5px;
    border-radius: 10px;
  }

  /* .opinion .upload .item img{
    width: 32.5px;
    height: 28.5px;
    border-radius: 10px;
} */
  .opinion .upload .item .big-img {
    width: 105px;
    height: 105px;
    border-radius: 10px;
  }

  .opinion .upload .item .img-delete {
    position: absolute;
    top: 0;
    right: 0;
    width: 21px;
    height: 21px;
	z-index: 30;
  }

  .opinion .btn {
    width: 336px;
    margin: 40px auto;
  }
  .uploader-img{
	  position: absolute;
	  top: 0;
	  left: 10px;
	  opacity: 0;
	  width: 90px;
	  height: 100px;
	  /* margin-left: 10px; */
  }
</style>
