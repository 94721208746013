<template>
    <div class="complaint-modal" 
        v-show="isShow"
    >
        <div class="modal">
            <div class="img">
                <img src="@/assets/yq/complaint-02.png" alt="">
            </div>
            <div class="text-box">
                <div class="title">
                    反馈已发送
                </div>
                <div class="content">
                    您的意见反馈已提交，我们会尽快回复！！
                </div>
                <div class="button">我知道了</div>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    props: {
        isShow: {
            type: Boolean,
        }
    },
    data() {
        return {
            textVal: ""
        }
    },
    methods: {
        btnCancel(){
            this.$emit("cancel");
        },
        
        
    },
};
</script>

<style scoped>

.complaint-modal{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgb(0, 0, 0, 0.5);
    z-index: 20;
}
.modal{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 291px;
    height: 325px;
    background: #fff;
    z-index: 50;
    border-radius: 6px;
}
.modal img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 325px;
}
.modal .text-box{
    position: relative;
    z-index: 60;
    padding:57.5px 0 0 33px;
}
.modal .text-box .title{
    font-size: 22px;
    font-weight: 500;
    color: #FEFEFE;
    line-height: 34px;
}   

.modal .text-box .content{
    margin-top: 14px;
    width: 100px;
    font-size: 12px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 18px;
}
.modal .text-box .button{
    margin-top: 109px;
    width: 239px;
    height: 44px;
    background: rgba(4, 113, 253, 0);
    border: 1px solid #FFFFFF;
    border-radius: 44px;
    color: #FEFEFE;
    text-align: center;
    line-height: 44px;
}
</style>